<template>
  <b-modal
    id="print-surgery-map-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span>Impressão do mapa cirúrgico</span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="rooms">Selecione o tipo de visualização</label>
            <CheckboxSelect
              id="rooms"
              :options="options"
              :value="value"
              label="Todas salas"
              placeholder="Selecionar"
              trackBy="id"
              optionLabel="label"
              @onChange="value => (this.value = value)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button
          variant="primary"
          :disabled="!value.length"
          class="mr-2"
          @click="printSurgicalMap"
        >
          Imprimir
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  name: 'PrintSurgicalMapModal',
  props: {
    options: Array,
    clinicId: String,
    date: String
  },
  data: () => ({
    value: []
  }),
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    CheckboxSelect: () => import('@/components/CheckboxSelect')
  },
  methods: {
    closeModal() {
      this.value = []
      this.$bvModal.hide('print-surgery-map-modal')
    },
    async printSurgicalMap() {
      const isLoading = this.$loading.show()
      try {
        const roomIds = this.value.map(room => room.id)
        const { data } = await this.api.printSurgicalMap(this.clinicId, roomIds, {date: this.date})
        
        if (!data?.length) {
          this.$toast.warning('Não há nenhuma cirurgia agendada para a(s) sala(s) selecionada(s).')
          return
        }

        data.map(item => {
          const pdfData = Uint8Array.from(window.atob(item.pdf), c =>
            c.charCodeAt(0)
          ).buffer
          const blob = new Blob([pdfData], { type: 'application/pdf' })
          saveAs(blob, `${item.name} - ${this.moment().format('DD-MM-YYYY')}`)
        })
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    }
  }
}
</script>

<style lang="scss">
#print-surgery-map-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;

    .card {
      border: none;
      display: flex;
      background-color: #e5f9ff;

      p,
      span {
        font-size: min(1.2vw, 16px);
        color: var(--type-active);
      }
      p {
        font-weight: 700;
      }

      span {
        font-weight: 600;
      }
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
